/* You can add global styles to this file, and also import other style files */
// Global Styles
@import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
@import "~ng-pick-datetime/assets/style/picker.min.css";
@import "../node_modules/bootstrap-icons/font/bootstrap-icons.css";
@import "~angular2-multiselect-dropdown/themes/default.theme.css";

html {
  height: 100%;
}

body {
  margin         : 0;
  padding        : 0;
  list-style-type: none;
  font-family    : 'helvetica-regular';
  font-weight    : 400;
  background-color: white !important;
}
.helvetica {
  font-family: 'helvetica';
}
.container {
  max-width: 1120px;
  margin   : 0 auto;
  padding  : 0;
}

/* RESPONSIVE STYLES */
/* Smartphones (portrait and landscape) ----------- */
@media (min-width: 576px) {
  .yellow-panel .row .pl-0 {
    padding-right: 0;
  }

  // .container {
  //   max-width: unset!important;
  // }
}

@media only screen and (min-width : 320px) and (max-width : 576px) {
  .row .col-sm-6.pl-0 {
    padding-right: 0;
  }

  .yellow-panel {
    padding-right: 0px;
  }

  .container {
    max-width: unset;
  }

  .vol-card-chart {
    .row {

      .col-md-4 {
        margin-top: 15px;
      }
    }
  }

  .vol-modal {
    border-radius: 0;
    margin-left  : 3px;
    width        : 94%;
  }


}

/* Smartphones (landscape) ----------- */
@media only screen and (min-device-width : 320px) and (max-device-width : 576px) and (orientation : landscape) {
  /* Styles */
}

/* Smartphones (portrait) ----------- */
@media only screen and (min-device-width : 320px) and (max-device-width : 576px) and (orientation : portrait) {
  /* Styles */
}


@media (max-width: 1024px) {

  .sidebar.no-slide {
    transform      : translateX(-185px);
    transition     : all 0.5s ease;
    //display      : none;
    // padding-left: 0;width: 0;
  }

  .slide-transition {
    transition: all 0.5s ease;
  }

  .toggle {
    display: none;
  }

  .sidebar.slide-component {
    transform : translateX(0px);
    transition: all 0.5s ease;
  }

  .slide-component {
    transform : translateX(185px);
    transition: all 0.5s ease;
  }

  .sidebar {
    position: fixed !important;
  }

  .page-content {
    padding-left: 0px !important;
  }

  .global-footer .forever-logo-container img {
    width: 300px;
  }

  .navbar-dark .navbar-toggler {
    color       : rgb(0, 0, 0);
    border-color: rgba(0, 0, 0, 1);
  }


}


/* iPads (portrait and landscape) ----------- */
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
  /* Styles */
}

/* iPads (landscape) ----------- */
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) {
  /* Styles */
}

/* iPads (portrait) ----------- */
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {
  /* Styles */
}

/**********
    iPad 3
    **********/
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) and (-webkit-min-device-pixel-ratio : 2) {
  /* Styles */
}

@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) and (-webkit-min-device-pixel-ratio : 2) {
  /* Styles */
}

/* Desktops and laptops ----------- */
@media only screen and (min-width : 1224px) {
  /* Styles */
}

/* Large screens ----------- */
@media only screen and (min-width : 1824px) {
  /* Styles */
}

/* iPhone 4 ----------- */
@media only screen and (min-device-width : 320px) and (max-device-width : 480px) and (orientation : landscape) and (-webkit-min-device-pixel-ratio : 2) {
  /* Styles */
}

@media only screen and (min-device-width : 320px) and (max-device-width : 480px) and (orientation : portrait) and (-webkit-min-device-pixel-ratio : 2) {
  /* Styles */
}

/* iPhone 5 ----------- */
@media only screen and (min-device-width: 320px) and (max-device-height: 568px) and (orientation : landscape) and (-webkit-device-pixel-ratio: 2) {
  /* Styles */
}

@media only screen and (min-device-width: 320px) and (max-device-height: 568px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2) {
  /* Styles */
}

/* iPhone 6, 7, 8 ----------- */
@media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation : landscape) and (-webkit-device-pixel-ratio: 2) {
  /* Styles */
}

@media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2) {
  /* Styles */
}

/* iPhone 6+, 7+, 8+ ----------- */
@media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation : landscape) and (-webkit-device-pixel-ratio: 2) {
  /* Styles */
}

@media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2) {
  /* Styles */
}

/* iPhone X ----------- */
@media only screen and (min-device-width: 375px) and (max-device-height: 812px) and (orientation : landscape) and (-webkit-device-pixel-ratio: 3) {
  /* Styles */
}

@media only screen and (min-device-width: 375px) and (max-device-height: 812px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3) {
  /* Styles */
}

/* iPhone XS Max, XR ----------- */
@media only screen and (min-device-width: 414px) and (max-device-height: 896px) and (orientation : landscape) and (-webkit-device-pixel-ratio: 3) {
  /* Styles */
}

@media only screen and (min-device-width: 414px) and (max-device-height: 896px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3) {
  /* Styles */
}

/* Samsung Galaxy S3 ----------- */
@media only screen and (min-device-width: 320px) and (max-device-height: 640px) and (orientation : landscape) and (-webkit-device-pixel-ratio: 2) {
  /* Styles */
}

@media only screen and (min-device-width: 320px) and (max-device-height: 640px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2) {
  /* Styles */
}

/* Samsung Galaxy S4 ----------- */
@media only screen and (min-device-width: 320px) and (max-device-height: 640px) and (orientation : landscape) and (-webkit-device-pixel-ratio: 3) {
  /* Styles */
}

@media only screen and (min-device-width: 320px) and (max-device-height: 640px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3) {
  /* Styles */
}

/* Samsung Galaxy S5 ----------- */
@media only screen and (min-device-width: 360px) and (max-device-height: 640px) and (orientation : landscape) and (-webkit-device-pixel-ratio: 3) {
  /* Styles */
}

@media only screen and (min-device-width: 360px) and (max-device-height: 640px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3) {
  /* Styles */
}
.cdk-global-scrollblock {
  overflow-y: hidden !important;
}
.card-display .owl-carousel .owl-stage-outer {
}
.fa {
  text-rendering: optimizeLegibility;
}



#next_level_dropdown {
  .dropdown-btn {
    height: 35px;
    overflow: auto;
    border-radius: 0;
    font-size: 12px;
    padding: 8px 12px;
  }
  .selected-item-container > .selected-item > a, .dropdown-list > .item1 , .dropdown-btn::-webkit-scrollbar{
    display: none;
    }
 
    .dropdown-btn{
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
    }
 
    .selected-item-container> .selected-item{
      border:none;
      background:none;
      margin-right: 0px;
      color: black;
    }
    
    .selected-item-container> .selected-item:hover{
      box-shadow: none;
    }
     
    .dropdown-btn> .selected-item-container::before{
      content:'+';
    }
    .dropdown-btn> .selected-item-container:first-child::before{
      content:''
    }
 
}
 
